export const DEFINITIONS = [
    "Abbreviation for American Standard for Computer Information Interchange. Pure and simple text file based on an American standard. A very common alphanumeric text interchange format. The term is used colloquially to refer to data that is stored in a text format that does not require a special program to decode and is usually somewhat comprehensible to a human reader.",
    "The range of colors that any given device or format is able to display or represent.",
    "To output in a format that is fixed. In this case the model is no longer able to be animated but exists essentially as a 3D model for each frame.",
    "split screen: a film or video technique in which the frame is divided into discrete nonoverlapping images",
    "A single image in a series of consecutive images. When you stop a video at any given point, the image that you see can be called a frame. A second of video is typically made of 24 or 30 frames per second, abbreviated as FPS.",
    "white point: (1) On a piece of film, the measured density in the area of least opacity (2) In a digital image, the numerical value that corresponds to the brightest area that will be represented when the image is eventually viewed in its final form.",
    "rotoscope: Originally the name of a device patented in 1917 by Max Fleischer to aid in cel animation. Now used as a general term for the process of creating imagery or mattes on a frame-by-frame basis by hand. Also referred to as a roto.",
    "Greyscale is a desaturated image with no color in it, a black and white version of your image. Greyscale maps can be used for DOF adjustment.",
    "premultiplied image: An image whose red, green, and blue channels have been multiplied by a matte. Usually this matte is stored as the alpha channel of this image.",
    "Industrial Light & Magic (ILM): A pioneering visual effects company that was the first to widely use digital compositing in feature-film work.",
    "(1) In the digital world, a combination of functions or effects that are grouped together to create a new effect. (2) A specialized lens that is capable of focusing at an extremely close distance to the subject.",
    "In a node-based workflow, nodes are essentially all the little actions you perform when working on your project – whatever modification you make, whatever detail you add, whatever setting you apply to your creation can be identified as a node. Together, they form node graphs.",
    "Sobel matte: A discrete differentiation operator, computing an approximation of the gradient of the image intensity function. In simplified words, it uses an edge detection algorithm to create an outline around the edge.",
    "An asset is anything you use to make a VFX shot that isn't the plate. There are 2D and 3D assets.",
    "LIDAR (light imaging detection and ranging ): (LiDAR and LADAR) A surveying method that measures distance to a target by illuminating the target with laser light and measuring the reflected light with a sensor. Differences in laser return times and wavelengths can then be used to make digital 3-D representations of the target.",
    "shutter angle: The part of a motion picture camera that determines how long a given area of film will be exposed to a scene. Most cameras have the ability to adjust their shutter angle. A larger shutter angle will result in increased motion blur on moving objects.",
    "In the HSV color space, the value equals the maximum of the red, green, and blue components.",
    "spline curve: A continuous smooth curve defined by a certain number of control points. ",
    "Noise, also known as film grain, can be an artifact or a camera effect added on purpose to achieve a more atmospheric look. It is an image distortion where you can see grainy areas, discoloration, fading, etc.",
    "Non-Uniform Rational B-Spline (NURBs): A mathematical model commonly used in computer graphics for generating and representing curves and surfaces. It offers great flexibility and precision for handling both analytic (surfaces defined by common mathematical formulae) and modeled shapes.",
    "LED (Light emitting diode): A semiconductor diode which glows when a voltage is applied.",
    "In-progress versions of a shot, designated by specific numerical take numbers, to serve as a record of the shot's progress at a particular point in its production history.",
    "motion tracking: Motion Tracking is a process of tracking the movement of objects in your shot. When you have the data about the movement of your elements within a shot you can manipulate them, add effects to them, paint on top of them, perform lookdev, etc. ",
    "floating point: A term used to describe a number in which no fixed number of digits must be used before or after a decimal point to describe a number, meaning that the decimal point can float.",
    "In digital paint, a method of copying information from one region of an image to another.",
    "(1) To adjust a lens so that the image it produces is as sharp as possible. (2) The point in space behind a lens where this sharpness occurs.",
    "screen right: The right side of the screen or image from the viewpoint of the viewer. Opposite of screen left.",
    "film gauge: The width of a particular film stock, that is, 16mm, 35mm, etc.",
    "Information about a particular shot that is placed at the head of the shot, before the actual image begins.",
    "practical effects (floor effects): Effects that are accomplished live, without any post-production. Practical effects include explosions, artificial rain, and smoke.",
    "high dynamic range (HDR): A technique for capturing the extended tonal range in a scene by shooting multiple pictures at different exposures and combining them into a single image file that can express a greater dynamic range than can be captured with current imaging technology. With HDR, you get a higher-quality picture on your screen – it becomes more dynamic, the colors are juicier, and overall the picture looks more striking thanks to broader color gamut and an increased contrast ratio.",
    "A photographic term referring to the appearance of point-of-light sources in an out-of-focus area of an image produced by a camera lens using a shallow depth of field.",
    "batch render: Batch rendering delivers an efficient approach to setting up a sequence of different tasks or scene states to render automatically. With batch rendering, you can continue working on a scene while it renders, launch renders remotely and render scenes faster.",
    "playback speed: The rate (usually measured in frames per second) at which a sequence of images is displayed.",
    "Lens flares are a camera lens effect that appears when blinding light penetrates the camera lens, hits the camera’s sensor, and scatters. While in photography and filmmaking a flare is often considered an artifact, fake lens flares are often added to the shots to make them more realistic.",
    "Macbeth chart: An industry standard test chart made up of square color and gray patches.",
    "clean plate: A plate that differs from the primary plate only in that it does not contain the subject(s) in the frame.",
    "A voxel is pretty much the same as a pixel but in a 3D space. Voxel art is similar to pixel art except instead of 2D squares the artists use 3D cubes to build up their scenes.",
    "matte painting: A hand-painted image, usually intended to be photorealistic, that is combined with live-action footage.",
    "raw stock: Unexposed, unprocessed film.",
    "A piece of original photography that is intended to be used as an element in a composite.",
    "Abbreviation for Society of Motion Picture and Television Engineers.",
    "aspect ratio: A single number that is the result of dividing the width of an image by its height. The units used to measure the width and height are irrelevant, since they will cancel when divided together to give a unitless result. See also pixel aspect ratio.",
    "Abbreviation for the British Academy of Film and TelevisionArts.",
    "A scaled-down image that is used as a stand-in for a higher resolution original.",
    "superimpose: To place one image on top of another, usually with some transparency involved.",
    "black point: (1) On a piece of film, the measured density in the area of greatest opacity. (2) In a digital image, the numerical value that corresponds to the darkest area that will be represented when the image is eventually viewed in its final form.",
    "Vimeo is an online video hosting platform. Though YouTube is the largest video hosting platform on the web, Vimeo is one of VFX artists’ most loved ones. Vimeo doesn’t have ads and provides higher resolution for your uploads which is why many professional digital artists and VFX studios use it to publish their demo reels.",
    "color script: A Color Script for Animation is a quick, working document that establishes which color combinations relate to specific emotional touch-points of the story. Animators establish relationships that they maintain (or break) based on what they want to communicate to the audience.",
    "A device or program that compresses data to enable faster transmission and decompresses received data.",
    "release print: A release print is a copy of a film that is provided to a movie theater for exhibition. ",
    "lossy compression: A method of compressing and storing a digital image in such a fashion that it is impossible to perfectly reconstruct the original image.",
    "ambient light: For computer graphics (CG), a directionless light source that uniformly distributes light in all directions, illuminating objects equally regardless of their surface orientation. CG ambient lighting is used as an inexpensive way to simulate the indirect illumination that occurs in the real world when light bounces off of other objects in the environment.",
    "The term given to a shot in an animated or VFX movie once it is considered complete and has been approved by the appropriate decision makers.",
    "SIGGRAPH: The Special Interest Group for Graphics, a subgroup under the Association for Computing Machinery, and the major organization for graphics professionals. Also, the annual conference sponsored by this group, which features a large number of courses and seminars – and some really big parties.",
    "focal length: A measure of the magnification power of a given lens, based on the distance from the center of the lens to the film. Also known as simply the length of a lens. A longer focal length will produce greater magnification than a shorter length.",
    "Leica lenses: The brand of lenses used on the animation cameras that photographed storyboards in the earliest Disney days.",
    "production sense: The near-mythical ability of an experienced digital artist to decide on the proper course of action when creating a visual effects shot.",
    "Originally an abbreviation for picture element, although the term pixel is generally considered to be an actual word nowadays. A digital image is composed of a rectangular array of individual colored points. Each one of these points is referred to as a pixel.",
    "depth of field (DOF): Depth of Field is the distance between the object closest to the camera and the farthest one from it. In VFX, depth of field determines which objects in the shot should appear sharper and more well-defined and which ones should be blurred out. DOF helps you make the shot more atmospheric and add volume to it.",
    "lookup table (LUT): An array of values used to convert data from an input value to a new output value. See also color lookup table.",
    "The individual particles of silver halide in a piece of film that capture an image when exposed to light. Because the distribution and sensitivity of these particles are not uniform, they are perceived (particularly when projected) as causing a noticeable graininess. Different film stocks will have different visual grain characteristics.",
    "short lens: A relative term, in contrast to a long lens. Also known as a wide-angle lens.",
    "An image used to define or control the transparency of another image. See also articulate matte, complementary matte, difference matte, edge matte, fixed matte, garbage matte, hold-out matte, rotoscoped matte, static matte, traveling matte.",
    "Unity is a cross-platform game engine from Unity Technologies. With Unity, you can design 2D and 3D games. It supports desktop, mobile, console, and virtual reality platforms but is especially popular among Android and iOS game developers.",
    "A number defined with floating point precision.",
    "prime lens: A camera lens with a fixed focal length, as opposed to a zoom lens, which has a variable focal length.",
    "composite video: Video signal in which the color elements are all combined (encoded) into a single signal.",
    "An alpha channel is a four-channel image (RGBA) an alpha channel is the one that stores transparency information. It is represented by a number between 0 and 1 where 0 is completely transparent and 1 is fully opaque.",
    "Abbreviation for motion capture. A technique whereby an individual being's performance is captured and translated for use in driving a CG being's performance. Also known as performance capture.",
    "A file with .tga extension is a raster graphic format and was created by Truevision Inc. It was designed for the TARGA (Truevision Advanced Raster Adapter) boards and provided Highcolor/truecolor display support for IBM-compatible PCs.",
    "color timer: A person who adjusts the scene-to-scene color continuity when preparing the final print of a film.",
    "render queue: The list of tasks waiting to be processed on a render farm.",
    "Any light in a scene that strikes an object it was not intended to illuminate.",
    "field of view (FOV): The range of a scene that will be captured by a specific camera. FOV is usually measured as the number of horizontal degrees (out of 360), although a vertical field of view is also a valid measurement.",
    "green screen: (in film and video techniques such as chromakey) a green background in front of which moving subjects are filmed and which allows a separately filmed background to be added to the final image.",
    "A digital effect used to modify the apparent brightness of an image.",
    "(1) The image captured by a camera. (2) A collection of shots that share a common setting or theme.",
    "Academy of Motion Picture Arts and Sciences",
    "fixed matte: As opposed to a traveling matte, a fixed matte will not change position or shape during the shot.",
    "Servers that are accessed over the Internet, and the software and databases that run on those servers.",
]