import { 
  getGameDate 
} from '../../lib/words'
import { 
  getIndex 
} from '../../lib/words'
import {
  WORDS
} from '../../constants/wordlist'
import {
  DEFINITIONS
} from '../../constants/definitions'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
}

export const AnswerModal = ({
  isOpen,
  handleClose,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
}: Props) => {
  return (
    <BaseModal
      title={"The Word is"}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <h2 className="text-4xl font-bold leading-6 text-gray-900 dark:text-gray-100">
        {WORDS[getIndex(getGameDate())]}
      </h2>
      <br></br>
      <h4 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
        {DEFINITIONS[getIndex(getGameDate())]}
      </h4>
    </BaseModal>
  )
}
