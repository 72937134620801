export const WORDS = [
    'ascii',
    'gamut',
    'baked',
    'split',
    'frame',
    'white',
    'scope',
    'scale',
    'image',
    'magic',
    'macro',
    'nodes',
    'sobel',
    'asset',
    'lidar',
    'angle',
    'value',
    'curve',
    'noise',
    'nurbs',
    'diode',
    'takes',
    'track',
    'point',
    'clone',
    'focus',
    'right',
    'gauge',
    'slate',
    'floor',
    'range',
    'bokeh',
    'batch',
    'speed',
    'flare',
    'chart',
    'clean',
    'voxel',
    'paint',
    'stock',
    'plate',
    'smpte',
    'ratio',
    'bafta',
    'proxy',
    'super',
    'black',
    'vimeo',
    'color',
    'codec',
    'print',
    'lossy',
    'light',
    'final',
    'graph',
    'focal',
    'leica',
    'sense',
    'pixel',
    'depth',
    'table',
    'grain',
    'short',
    'matte',
    'unity',
    'float',
    'prime',
    'video',
    'alpha',
    'mocap',
    'targa',
    'timer',
    'queue',
    'spill',
    'field',
    'green',
    'gamma',
    'scene',
    'ampas',
    'fixed',
    'cloud',
]